import { VStack } from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import { FC } from "react";
import Meta from "src/components/Meta";
import { linkResolver } from "src/lib/linkResolver";
import SLICES from "src/slices";
import SliceZone from "src/slices/SliceZone";
import PagePricingPlans from "./slices/PricingPlans";
import PageLayout from "src/layouts/PageLayout/PageLayout";
import { AntiFlicker } from "src/components/GoogleOptimize";
import { breadcrumbMarkup } from "src/components/Meta/Markups/utils";
import { ImageObjectMarkupType } from "src/components/Meta/Markups/imageObjectMarkup";

const PrismicPage: FC<PageProps<Gatsby.PrismicPageQuery>> = ({
  data: { prismicPage }
}) => {
  if (!prismicPage?.data?.body) throw Error();

  const {
    body,
    indexed,
    social_media_thumbnail,
    social_media_title,
    meta_description,
    meta_title,
    site_footer_override,
    has_antiflicker_snippet,
    hotjar,
  } = prismicPage.data;

  const path = linkResolver(prismicPage);

  let thumbnailUrl: string | undefined;
  let imageObjectMarkup: ImageObjectMarkupType | undefined;

  if (social_media_thumbnail?.document?.__typename === "PrismicElementImage" && social_media_thumbnail.document.data?.image_file) {
    const { url, dimensions, alt } = social_media_thumbnail.document.data.image_file;
    thumbnailUrl = url;
    imageObjectMarkup = {
      '@id': `${process.env.GATSBY_HOST}/#/schema/page/image/`,
      url,
      contentUrl: url,
      width: String(dimensions?.width),
      height: String(dimensions?.height),
      caption: alt,
    }
  }

  return (
    <PageLayout footer={site_footer_override?.document} >
      {has_antiflicker_snippet && <AntiFlicker />}
      <Meta
        url={path}
        indexed={indexed}
        title={meta_title}
        metadescription={meta_description}
        socialMediaTitle={social_media_title}
        socialMediaImage={thumbnailUrl}
        hotjar={hotjar}
        {...(path !== '/' ? { breadcrumbMarkup: breadcrumbMarkup({ name: meta_title!, path }) } : {})}
        {...(imageObjectMarkup ? { imageObjectMarkup } : {})}
        webPageMarkup={{
          name: meta_title,
          description: meta_description,
          thumbnailUrl,
          datePublished: prismicPage.first_publication_date,
          dateModified: prismicPage.last_publication_date,
        }}
      />

      <VStack
        spacing="0"
      >
        <SliceZone
          body={body}
          slices={{
            ...SLICES,
            pricing_plans: PagePricingPlans,
          }}
        />
      </VStack>
    </PageLayout>
  );
};

export default PrismicPage;

export const query = graphql`
  query PrismicPage($uid: String) {
    prismicPage(uid: { eq: $uid }) {
      type
      uid
      first_publication_date
      last_publication_date

      data {
        indexed
        has_antiflicker_snippet
        site_footer_override {
          document {
            ...SiteFooter
          }
        }
        meta_description
        meta_title
        social_media_thumbnail {
          document {
            __typename
            ...ElementImageUrl
          }
        }
        social_media_title
        hotjar

        body {
          __typename
          ...PrismicSliceMetadata

          ...PageHeader
          ...PageHeaderSpotlight
          ...PageBlockColumns
          ...PageBlockCta
          ...PageBlockRelation
          ...PageBlockAccordion
          ...PageBlockFaq
          ...PageBlockCardsRedirection
          ...PageBlockMatrix
          ...PageBlockProductRecap
          ...PageBlockTestimonial
          ...PageBlockHighlightCards
          ...PageBlockTestimonialCarousel
          ...PageBlockPricingDisplay
          ...PageBlockSteps
          ...PageBlockIllustratedList
          ...PageBlockArticlesCarousel
          ...PagePricingPlans
          ...PageBlockRichContent
          ...PageFloatingBanner
          ...PagePrebuiltBlock
          ...PageBlockBanner
          ...PageBlockVideo
          ...PageBlockPlansPresentation
          ...PageBlockCreationPackagePricing
          ...PageBlockPricingTable
          ...PageSubPages
          ...PageBlockTitle
          ...PageBlockSecurity
          ...PageBlockPersona
          ...PageBlockMission
          ...PageBlockCreationRedirection
          ...PageBlockTrioProductHighlight
          ...PageBlockTetrisProductHighlight
          ...PageBlockFeatureCarousel
          ...PageBlockCenter
          ...PageBlockAnimatedTestimonial
          ...PageBlockNumber
          ...PageBlockHubspotForm
          ...PageBlockEmbedHubspotForm
          ...PageBlockFilterableList
          ...PageBlockTrioOffer
        }
      }
    }
  }
`;
